import React, { memo } from 'react';
import { Icon } from '@common/components/icon';
import { EFileTypes, fileTypesStyles } from '@common/definitions';

import type { Document } from '../../types';
import { combineClassNames } from '@common/utils/combineClassNames';

type Props = {
  item: Document,
};

export const DocumentIcon = memo(({ item }: Props) => {
  if (item.page?.icon) {
    return (
      <span
        className={combineClassNames(
          'DocumentIcon',
          'tw-w-[36px] tw-h-[36px] tw-mr-3 tw-rounded-[25%]',
          'tw-flex tw-items-center tw-justify-center',
          'tw-bg-slate-50 tw-text-2xl',
        )}
      >
        {item.page.icon}
      </span>
    );
  }

  if (item.type === 'personal_folder') {
    return (
      <div className="DocumentIcon DocumentIcon--personal">
        <Icon type="folder__filled" />
      </div>
    );
  }

  if (item.is_folder) {
    return (
      <div className="DocumentIcon DocumentIcon--folder">
        <Icon type="folder__filled" />
      </div>
    );
  }

  if ((item.attachment?.file_type === 'video' || item.attachment?.file_type === 'image') && item.attachment?.thumb_path) {
    return <div className="DocumentIcon DocumentIcon--thumb" style={{ backgroundImage: `url(${item.attachment.thumb_path})` }} />;
  }

  return (
    <img
      src={
        item.page ?
          fileTypesStyles[EFileTypes.DOCUMENT] :
          fileTypesStyles[item.attachment?.file_type || 'null'] || fileTypesStyles.null
      }
      alt={item.attachment?.file_type || 'Icon'}
      className="DocumentIcon"
    />
  );
// });
}, (prevProps, nextProps) => {
  // Warning!: custom memo isEqual function, can cause the component to no update while it should
  if (prevProps.item.page && nextProps.item.page && prevProps.item.page?.icon === nextProps.item.page?.icon) {
    return true;
  }
  if (prevProps.item.is_folder &&
    prevProps.item.is_folder === nextProps.item.is_folder && prevProps.item.type === nextProps.item.type) {
    return true;
  }
  // Check if the actual path has changes without the query params
  if ('attachment' in nextProps.item && 'attachment' in prevProps.item) {
    return prevProps.item.attachment?.thumb_path?.split('?')[0] === nextProps.item.attachment?.thumb_path?.split('?')[0];
  }
  return false;
});
