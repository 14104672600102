import React from 'react';
import { useSelector } from 'react-redux';
import { TFunction, useTranslation } from 'react-i18next';
import moment from 'moment';

import { Icon } from '@common/components/icon';
import Attachment from '@common/components/attachment';
import { QuestionIcon } from '../../../survey/components/question-icon';
import { EQuestionTypes } from '../../../survey/definitions';

import type { FormSubmissionAnswer } from '../../types';
import { StoreState } from '@common/types/store';
import { Checkbox } from '@common/components/form/inputs/checkbox';

const getAnswer = (item: FormSubmissionAnswer, t: TFunction, locale: string) => {
  switch (item.question.type) {
    case EQuestionTypes.DROPDOWN: {
      const { options } = item.question.parameters;

      if (!Array.isArray(item.answer)) return <small>{t('survey:survey_response_no_answer')}</small>;

      return item.answer
        // @ts-expect-error
        .map((optionId: string) => {
          const option = options.find((o) => o.id === optionId);

          return option?.value;
        })
        .filter((o: any) => !!o).join(', ');
    }
    case EQuestionTypes.BOOLEAN:
      if (item.answer === null) return <small>{t('survey:survey_response_no_answer')}</small>;

      return (item.answer === true
        ? item.question.parameters.label_true
        : item.question.parameters.label_false) as string;
    case EQuestionTypes.DATE_TIME: {
      if (!item.answer) return <small>{t('survey:survey_response_no_answer')}</small>;

      const icon = item.question.parameters.allow_date_select
        ? <Icon type="calendar_today__filled" />
        : <Icon type="access_time" />;

      // @ts-expect-error
      const dateTimeObj = moment(item.answer, ['YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'HH:mm:ss']);
      const strings = [];

      if (item.question.parameters.allow_date_select) strings.push(dateTimeObj.format('D MMMM YYYY'));
      if (item.question.parameters.allow_time_select) strings.push(dateTimeObj.format('HH:mm'));

      return (
        <>
          {icon}
          {strings.join(` ${t('forms:at').toLowerCase()} `)}
        </>
      );
    }
    case EQuestionTypes.FILE_UPLOAD:
      if (!Array.isArray(item.answer) || item.answer.length === 0) return <small>{t('survey:survey_response_no_answer')}</small>;
      // @ts-expect-error
      return item.answer.map((attachment) => <Attachment key={attachment.id} item={attachment} />);
    case EQuestionTypes.CHECKLIST:
    case EQuestionTypes.MULTIPLE_CHOICE:

      const arr = Array.isArray(item.answer) ? item.answer : [item.answer];
      return (
        <div className="Response__Answers__Item__Checklist">
          {item.question.parameters.options.map((option: any) => {
            return (
              <Checkbox
                key={option.id}
                value={arr.includes(option.id)}
                label={option.value}
              />
            );
          })}
        </div>
      );

    case EQuestionTypes.NUMBER:
      if (!item.answer) return <small>{t('survey:survey_response_no_answer')}</small>;
      return Intl?.NumberFormat?.(locale, {
        useGrouping: false,
        maximumFractionDigits: 20,
      }).format(parseFloat(item.answer as string));
    default:
      return item.answer || <small>{t('survey:survey_response_no_answer')}</small>;
  }
};

type Props = {
  item: FormSubmissionAnswer;
  timezone: {
    name: string,
    offset_to_gmt_in_seconds: number;
  };
};

export const FormAnswer = ({ item, timezone }: Props) => {
  const { t } = useTranslation();

  if (!item.question) return null;

  const locale = useSelector((state: StoreState) => state.loggedUser.user.language.locale);
  const answer = getAnswer(item, t, locale);
  const elements = Array.isArray(answer)
    ? answer.map((a) => <div className="Response__Answers__Item__Answer fs-exclude">{a}</div>)
    : <div className="Response__Answers__Item__Answer fs-exclude">{answer}</div>;

  return (
    <div className="Response__Answers__Item">
      <div className="Response__Answers__Item__Question">
        <QuestionIcon type={item.question.type} />
        {item.question.parameters.text || <small>{t('survey:survey_response_question_no_text')}</small>}
        {item.question.type === EQuestionTypes.SLIDER && (
          <span>
            {` (1 - ${item.question.parameters.amount_of_steps})`}
          </span>
        )}
      </div>
      <div className="Response__Answers__Item__Content">
        {elements}
        {item.question.type === EQuestionTypes.DATE_TIME && item.answer && (
          <div className="Response__Answers__Item__Timezone">
            {`${t('forms:timezone')}: ${timezone.name}`}
          </div>
        )}
      </div>
    </div>
  );
};
