import { useEffect, useState } from 'react';

import { EPredicateFilters, PredicateFilterItem } from '@common/components/predicates-filter';
import { Team, Network } from '@common/types/objects';
import { EPredicateFields, EPredicateOperators } from '@common/definitions';

const getNetworkPredicate = (networks?: Network[], value?: any[]) => {
  return {
    filter: EPredicateFilters[EPredicateFields.NETWORK],
    value: {
      attribute: EPredicateFields.NETWORK,
      comparison: EPredicateOperators.IN,
      value: value || [],
    },
    options: networks || [],
  };
};

const getFunctionPredicate = (functions?: Team[], value?: any[]) => {
  return {
    filter: EPredicateFilters[EPredicateFields.FUNCTION],
    value: {
      attribute: EPredicateFields.FUNCTION,
      comparison: EPredicateOperators.IN,
      value: value || [],
    },
    options: functions || []
  };
};

export const usePredicateState = ({
  networks,
  functions,
  defaultNetworks,
  defaultFunctions,
}: {
  networks?: Network[];
  functions?: Team[];
  defaultNetworks?: Network[];
  defaultFunctions?: Team[];
}): [PredicateFilterItem[], any] => {
  const [predicates, setPredicates] = useState(() => [
    ...(networks ? [getNetworkPredicate(networks, defaultNetworks)] : []),
    ...(functions ? [getFunctionPredicate(functions, defaultFunctions)] : []),
  ]);

  useEffect(() => {
    const networkPredicate = networks ?
      [
        getNetworkPredicate(
          networks,
          predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.NETWORK])?.value?.value || []
        )
      ] : [];
    const functionPredicate = functions ?
      [
        getFunctionPredicate(
          functions,
          predicates.find((p) => p.filter === EPredicateFilters[EPredicateFields.FUNCTION])?.value?.value || []
        )
      ] : [];
    setPredicates((prev) => {
      const newPredicates = [
        ...(networks ? networkPredicate : []),
        ...(functions ? functionPredicate : []),
      ];
      return JSON.stringify(prev) === JSON.stringify(newPredicates) ? prev : newPredicates;
    });
  }, [networks, functions]);

  return [predicates, setPredicates];
};
